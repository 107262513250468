
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "./config/config";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import Navbar from "./separate/Navbar";
// import Footer from "./separate/Footer";
// import Navigate from "../components/navigate";
import DataTable, { createTheme } from 'react-data-table-component';
import { BiArrowBack, BiLeftArrowAlt } from 'react-icons/bi';
import { AiFillCopy } from 'react-icons/ai';
import $ from 'jquery'


createTheme('solarized', {
    text: {
        primary: '#FFF',
        secondary: '#FFF',
    },
    background: {
        default: 'rgba(31, 125, 192, 0.1)',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#00e5ff21',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


function Leaderboard() {

    const userAddress = sessionStorage.getItem("chainaccounts");
    const [userData, setUserData] = useState([]);
    const [user, setUser] = useState([]);
    const [referLink, setReferLink] = useState("Referralcode");
    const [copied, setCopied] = useState(false);
   
    useEffect(() => {
        if (userAddress) {
            let userAddres = userAddress.toLowerCase();
            axios({
                method: "POST",
                url: `${config.APP_API_URL}referrals`,
                data: {
                    userAddress: userAddres
                },
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                },
            }).then((userDetails) => {
                if(userDetails.data.data){
                    setUserData(userDetails.data.data)
                }else{
                    setUserData([])
                }
                
            }).catch((err) => console.log(err))
        }

    }, []);




    useEffect(() => {
        if (userAddress) {
            let userAddres = userAddress.toLowerCase();
            axios({
                method: "POST",
                url: `${config.APP_API_URL}user_list`,
                data: {
                    userAddress: userAddres
                },
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                },
            }).then((singleUser) => {
                if(singleUser.data.data==null){
                    $("#referrallink").addClass("d-none");

                }else{
                    $("#referrallink").removeClass("d-none");
                 setReferLink(singleUser.data.data._id)
                 setUser(singleUser.data.data)
                }
            }).catch((err) => console.log(err))
        }
    }, [])

    const handleSort = (column, sortDirection) => console.log(column.selector, sortDirection);
    
    const columns = [
        {
            name: "User ID",
            selector: (row) => row._id,
            sortable: true
        },
        {
            name: "User Address",
            selector: (row) => row.userAddress,
            sortable: true
        },
        {
            name: "Amount",
            selector: (row) => ((row.first_purchase_dcx_amt/100)*5),
            sortable: true
        }
    ];


    const handleRowClicked = (row) => {
        console.log(row.WalletID);
    };

    const handleclick = (event) => {
        if (event.target.name == "refer_a_friend") {
            setCopied(true)
        }
    }
    
    return (
        <div className="App">
            {/* <Navigate /> */}
            <div className="container py-5">
                <div className="row">
                    <div className="">
                        <div className="text-start mb-lg-0 mb-3">
                            <a href="/"><span className="trans-back-button"><BiLeftArrowAlt /></span></a>
                        </div>
                        <div className="">
                           <span className="ReferalBoard-text-1 mt-2">ReferalBoard</span> 
                        </div>
                    </div>

                    <div className="d-flex flex-row mt-5 d-none" id="referrallink">
                        <div className="referal-copy-inner-section mx-auto">
                        {`${config.REACT_APP_URL}referral/${referLink}`}
                            {/* {`${REFERRAL_LINK}referral?referralCode=UNIQUE_CODE`} */}
                            <CopyToClipboard
                                disabled={false}
                                // text={`${REACT_APP_URL}/dashboard?referId=R-${this.state.useraddress.slice(-5)}`}
                                text={`${config.REACT_APP_URL}referral/${referLink}`}

                            // onCopy={() => this.setState({copied: true})}
                            >
                                <button type="button"
                                    className="leaderboard-button-copy"
                                    name='refer_a_friend'
                                    onClick={handleclick}
                                >{copied ? "Copied" : "Copy"}</button>
                            </CopyToClipboard>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col py-5">
                        <DataTable
                            columns={columns}
                            data={userData}
                            defaultSortFieldId
                            pagination={5}
                            onRowClicked={handleRowClicked}
                            highlightOnHover
                            // onRowClicked={handleRowClicked}
                            theme="solarized"
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </div>
            {/* <Footer /> */}

        </div>
    );
}
export default Leaderboard;

