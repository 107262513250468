import React, { useState, useEffect } from "react";
import { BsWallet2 } from 'react-icons/bs';

import $ from "jquery";
import config from "../config/config";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3'


const WalletConnector = (props) => {

    const [connectedAccount, setConnectedAccount] = useState("");
    const [buttondisconnect,setbuttondisconnect] = useState("False")
    const navigate = useNavigate();


    const connectWeb3Wallet = async () => {
        const web3js = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        const chainId = config.BNB_CHAINID;
        const curchain = web3js.utils.toHex(chainId)
        const currentchainId = await window.ethereum.request({ method: "eth_chainId" });
        if (accounts) setConnectedAccount(accounts[0]);
        sessionStorage.setItem('chainaccounts', accounts[0])
        $("#hidedisconnect").show();  
        getruseraddr(accounts[0]);
        setConnectedAccount(accounts[0].slice(0,7)+".."+accounts[0].slice(-5))
        if (currentchainId !== curchain) { 
            const chainIdnumber = '97';
            const rpcURL = config.bsctestnetrpcurl;
            const networkName = 'BSC Smart Chain';
            const currencyName = 'BNB';
            const currencySymbol = 'BNB';
            const explorerURL = 'https://bscscan.com/';
            const chainId = await web3js.utils.toHex(chainIdnumber);
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: web3js.utils.toHex(config.BNB_CHAINID) }]
                });
            } catch (error) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                    {
                        chainId: chainId,
                        chainName: networkName,
                        rpcUrls: [rpcURL],
                        blockExplorerUrls: [explorerURL],

                        nativeCurrency: {
                        name: currencyName,
                        symbol: currencySymbol,
                        decimals: 18,
                        },
                    },
                    ],
                });
            }
        }
        
    };


    const disconnectWeb3Modal = async () => {
        setConnectedAccount("");
        setConnectedAccount(null)
        setbuttondisconnect("True");
        sessionStorage.removeItem('chainaccounts')
        $("#hideconnect").show(); 
        $("#hidedisconnect").hide(); 
        window.location.reload();
    };

   

    const getruseraddr = async(addre) => {
        let addr = addre.toLowerCase();
        let transferobjects = {
            userAddress: addr
        }
        const getaddr = await axios.post(`${config.APP_API_URL}getuser`, transferobjects);
        if(getaddr.data.data != null) {
            navigate('/');
        }
    }


    useEffect(() => {
        const chainaccounts = sessionStorage.getItem('chainaccounts')
        if(chainaccounts == null && buttondisconnect == "False") {  
          setConnectedAccount(null);
            $("#hidedisconnect").hide(); 
            $("#hideconnect").show(); 
            setConnectedAccount("CONNECT WALLET");
        }
        else {
            $("#hidedisconnect").show(); 
            setConnectedAccount(chainaccounts.slice(0,7)+".."+chainaccounts.slice(-5))
            getruseraddr(chainaccounts)
        }
    },[]);

    return (
        <>  
            <button className="nav-link connect-wallet-button  mt-2"  id="hideconnect" onClick={connectWeb3Wallet}>
                    <BsWallet2 className='me-2' />
                {connectedAccount == "" || connectedAccount == null  ? "CONNECT WALLET":connectedAccount} 
            </button> 
            <button className="nav-link connect-wallet-button-2 mt-2 ms-2" id="hidedisconnect" onClick={disconnectWeb3Modal}>
                <BsWallet2 className='me-2' />
                {connectedAccount !== ""?"Disconnect":""}
            </button>
        </>
    )
};
export default WalletConnector;