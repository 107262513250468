import { useState, useEffect } from "react";
import $ from "jquery";
import './css/style.css'
import Web3 from 'web3'
import bscimage from './images/bnb.png'
import axios from 'axios';
import InputDataDecoder from 'ethereum-input-data-decoder'
import { homebridgeAbi } from './Abi/homebridgeabi'

import { BiArrowBack, BiLeftArrowAlt } from 'react-icons/bi';
import DataTable, { createTheme } from 'react-data-table-component';
import config from './config/config';
import { toast } from "react-toastify";


createTheme('solarized', {
    text: {
        primary: '#FFF',
        secondary: '#FFF',
    },
    background: {
        default: 'rgba(31, 125, 192, 0.1)',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#00e5ff21',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');


function Transaction() {

    const [gettxdetails,setgettxdetails] = useState([]);

    const columns = [
        {
            name: "Date",
            selector: (row) => row.sno,
            sortable: true
        },
        {
            name: "Tnx Hash",
            selector: (row) => <a href={row.link} target={"_blank"}>{row.hash}</a>,
            sortable: true,
        },
        {
            name: "From",
            selector: (row) => row.from,
            sortable: true,
            width: "200px"
        },
        {
            name: "To",
            selector: (row) => row.to,
            sortable: true,
            width: "200px"
        },
        {
            name: "Amount",
            selector: (row) => row.value,
            sortable: true,
        },
    ];

    const handleSort = (column, sortDirection) => console.log("=====",column.selector, sortDirection);


    useEffect(() => {
        if(window.ethereum) {
            const reloadCount = sessionStorage.getItem('chainaccounts');
            if(reloadCount != null) {
                getchainid(reloadCount)
            }
            else {
                toast.error("Connect Wallet");
            }
        }
    },[gettxdetails])

    const getchainid = async (address) => {
        const chainid = await window.ethereum.request({ method: "eth_chainId" });
        if (chainid === config.BNB_CHAINID) {
                $(".table-bsc").show();
                $('.switch-tabs-section-eth').css('background', 'transparent');
                $('.switch-tabs-section-eth').css('color', '#FFF');
                $('.switch-tabs-section-poly').css('background', 'transparent');
                $('.switch-tabs-section-poly').css('color', '#FFF');
                $('.switch-tabs-section-bsc').css('background', '#1b9ffe');
                $('.switch-tabs-section-bsc').css('color', '#FFF');
                const decoder = new InputDataDecoder(homebridgeAbi);
                var web3js = new Web3(window.ethereum);
                const gettxdetails = ['']
                 const responres = await axios.get("https://api.bscscan.com/api?module=account&action=txlist&contractaddress=0xb5a40bd33ab8a9bf4212a19c4f480fd8ea521542&address=" + address + "&page=1&offset=100&sort=desc&apikey=NCN23G4SGIEYWR3XWGR3XXWKMXT61BPE4I"); 
                 for (var i = 0; i < responres.data.result.length; i++) {
                    var car = {};
                    if (responres.data.result[i].to == "0xb5a40bd33ab8a9bf4212a19c4f480fd8ea521542") {
                        var dateend = new Date(responres.data.result[i].timeStamp * 1000);
                        var utcTime = dateend.toUTCString().replace('GMT', 'UTC');
                        const resultinput = await decoder.decodeData(responres.data.result[i].input);
                        if (resultinput.inputs[1]._hex == '0x00') {
                            var car = {
                                "sno": utcTime,
                                "link": "https://bscscan.com/tx/" + responres.data.result[i].hash,
                                "hash": responres.data.result[i].hash,
                                "from": responres.data.result[i].from,
                                "to": responres.data.result[i].to,
                                "value": await web3js.utils.fromWei(responres.data.result[i].value, 'ether') + " BNB"
                            }
                            gettxdetails.push(car);
                        } else {
                            const hexToDecimal2 = (parseInt(resultinput.inputs[1]._hex, 16)) / 1e18;
                            var car = {
                                "sno": utcTime,
                                "link": "https://bscscan.com/tx/" + responres.data.result[i].hash,
                                "hash": responres.data.result[i].hash,
                                "from": responres.data.result[i].from,
                                "to": responres.data.result[i].to,
                                "value": hexToDecimal2 + " USDT"
                            }
                            gettxdetails.push(car);
                        }
                        const setpaths = gettxdetails.filter(Boolean);
                        setgettxdetails(setpaths)
                    }
                }
        }
    }

   
    return (
        <div className="App">
            <section className='py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-12 switch-tabs-section'>
                            <div className="text-start mb-lg-0 mb-3">
                                <a href="/"><span className="trans-back-button"><BiLeftArrowAlt /></span></a>
                            </div>
                            <div className="btn-group switch-tabs-btn-group">
                                <button type="button"  className="btn switch-tabs-section-bsc"><img className="network-image" src={bscimage} alt="BSC Network" />BSC Network</button>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-5 main-child-arrow">
                            <div className="table-bsc gSVZoG">
                                <DataTable
                                className="arrow-child"
                                    columns={columns}
                                    data={gettxdetails}
                                    defaultSortFieldId
                                    pagination={5}
                                    highlightOnHover
                                    theme="solarized"
                                    onSort={handleSort}
                                />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Transaction;
